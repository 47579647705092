<template>
  <div>
      <div class="d-flex mr-auto">
        <v-img
          contain
          max-width='200px'
          src="../assets/clover-logo-light (1).svg"
          alt="clover-logo"
          class="pb-2 "
        ></v-img>
      </div>
    <v-row class='mt-10 d-flex justify-center align-center container'>
      <v-col cols='lg-6 sm-0' class='d-none d-sm-block' >
        <v-img
          aspect-ratio='3/4'
          contain
          src="../assets/phone.svg"
          alt="image"
        ></v-img>
      </v-col>
      <v-col cols='lg-6 sm-12' class="contentContainer">
        <div>
          <h2>Feedback Tool</h2>
          <p class="font-weight-medium">You choose - we form</p>
          <v-btn elevation="5"  width='20%' dark large color="purple" type="button" class='btn'>
            <GoogleLogin :onSuccess="onSuccess" :params="params">Login </GoogleLogin>
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import GoogleLogin from 'vue-google-login';
import config from '../config';

export default {
  name: 'landingPage',

  components: {
    GoogleLogin
  },
  methods: {
    onSuccess: function(googleUser) {
      const user = googleUser.getBasicProfile();

      if (!config.whiteListDomains.includes(user.getEmail().split('@')[1])) {
        alert('Your domain is not allowed');
        return;
      }

      const payload = {
        user: {
          name: user.getName(),
          email: user.getEmail(),
          image: user.getImageUrl()
        },
        token: googleUser.getAuthResponse().id_token
      };

      this.$store.dispatch('auth/login', payload);
    }
  },
  data: () => ({
    params: {
      client_id: config.googleAuth.clientId
    }
  })
};
</script>

<style>
.contentContainer {
  margin-top: -25px;
}

.contentContainer h2 {
  color: #92278e;
  font-size: 4em;
  font-weight: 900;
  font-family: 'Ubuntu', sans-serif;
}

.contentContainer h2:first-letter {
  font-size: 100px;
  color: black;
}

.contentContainer p {
  font-size: 2em;
  font-family: 'Ubuntu', sans-serif;
}

@media (max-width: 600px) {
  .contentContainer {
    margin-top: 30px;
  }

  .contentContainer h2 {
    font-size: 3em;
  }
  .contentContainer h2:first-letter {
    font-size: 70px;
  }

  .contentContainer p {
    font-size: 1.5em;
  }
  .btn {
    width: 60px;
  }

}

</style>
